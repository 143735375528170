<template>
  <div></div>
</template>

<script>
  export default {
    created() {
      let that = this;
      // 判读是否是移动端
      if (that.isMobile()) {
        window.location.href = that.$WebUrl() + '/mobile/#/pages/Teacher/BehaviorRecord/ClassIndex';
      } else {
        window.location.href = that.$WebUrl() + '/#/Admin/Adviser/Morality/Index';
      }
    },
    methods: {
      /**
       * @description 用于判断是否为移动端
       */
      isMobile() {
        let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
        return flag;
      },
    }
  };
</script>

<style>
</style>
