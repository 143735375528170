<script>
export default {
  created() {
    const { query } = this.$route;
    const path = query.path;
    let tpQuery = {};
    // 过滤 path
    for (const key in query) {
      if(key!='path'){
        tpQuery[key]=query[key];
      }
    }
    // 重新定位
    this.$router.replace({
      path: path,
      query: tpQuery
    });
  },
  mounted() {},
  render: function(h) {
    return h() // avoid warning message
  }
}
</script>
