<!--
  处分管理 - 新增
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-card class="box-card">
        <div slot="header" class="card-header ">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{ Title }}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :lg="12" :sm="24">
              <el-form-item label="处分号" prop="punishNo">
                <el-input v-model="ruleForm.punishNo" placeholder="请输入处分号" class="width_100Pie"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :sm="24">
              <el-form-item label="处分日期" prop="punishDate">
                <el-date-picker :key="random" value-format="yyyy-MM-dd" :picker-options="pickerOptions"
                  v-model="ruleForm.punishDate" type="date" placeholder="请选择学期范围内的日期" class="width_100Pie">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="处分原因" prop="reason">
                <el-input v-model="ruleForm.reason" placeholder="请输入原因" class="width_100Pie"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input class="formControl" type="textarea" :rows="4" maxlength="500" show-word-limit
                  placeholder="请输入内容" v-model="ruleForm.remark">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            处分学生名单
          </h3>
          <div class="card-tools">
            <ul class="nav">
              <li class="nav-item">
                <comLinkageClass ref="LinkageClass" comPlaceholder="请选择获奖学生" :comData="optAllStudent" :comMultiple="true"
                  @change="onChangeLinkageClass" comClass="width_400"></comLinkageClass>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body p-0">
          <el-table stripe :data="selectStudentList">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="studentName" label="学生"></el-table-column>
            <el-table-column prop="className" label="班级"></el-table-column>
            <el-table-column prop="studentNo" label="学号"></el-table-column>
            <el-table-column label="选择处分等级">
              <template slot-scope="scope">
                <el-select v-model="scope.row.levelId" placeholder="请选择">
                  <el-option v-for="item in Levels" :key="item.levelId" :label="item.name" :value="item.levelId">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-button size="mini" type="danger" @click="onDeleteSelect(scope.row.studentId)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <!--附件-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            附件
          </h3>
        </div>
        <div class="card-body">
          <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles"></comUpload>
        </div>
      </el-card>


      <div class="row">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import comUpload from '@/components/Upload.vue';
import comLinkageClass from "@/components/LinkageClass.vue";

var punishDateMin = null;
var punishDateMax = null;

function disabledDateTest(date) {
  if (punishDateMin != null && typeof (punishDateMin) != "undefined" && punishDateMax != null && typeof (
    punishDateMax) != "undefined") {
    return new Date(date).getTime() < new Date(punishDateMin).getTime() || new Date(date).getTime() > new Date(
      punishDateMax).getTime();
  } else if (punishDateMin != null && typeof (punishDateMin) != "undefined" && (punishDateMax == null || typeof (
    punishDateMax) == "undefined")) {
    return new Date(date).getTime() < new Date(punishDateMin).getTime();
  } else if ((punishDateMin == null || typeof (punishDateMin) == "undefined") && punishDateMax != null && typeof (
    punishDateMax) != "undefined") {
    return new Date(date).getTime() > new Date(punishDateMax).getTime();
  }
  return false;
}
export default {
  components: {
    comUpload,
    comLinkageClass
  },
  data() {
    return {
      content: '',
      editorOption: {},
      dialogTableVisible: false,
      dialogStudentName: null,
      dialogClassName: null,
      dialogStudentNo: null,
      isOne: false, //是否只能选择一个学生
      studentPunsihs: [], //单个学生的历史处分
      selectStudentList: [], //选中的学生集合
      selectStudentListReset: [], //选中的学生集合
      random: 0, //刷新组件
      punishDateMin: null,
      punishDateMax: null,
      pickerOptions: { //处分时间不能超出学期的时间范围
        disabledDate(time) {
          return disabledDateTest(time);
        },
      },
      historyStudent: [],//历史选择的学生
      ruleForm: //表单数据
      {
        punishId: null, //处分主键，新增时为null
        semesterId: null, //学期
        punishNo: null, //处分号
        punishDate: null, //处分日期
        reason: null, //处分原因
        levelId: null, //默认处分级别主键
        remark: null, //备注
        GrowthConfigs: null, //综合类型集合
        members: [],
        attachment: null,
      },
      optAllStudent: [],
      allStudents: [], //所有学生集合
      selectClassId: null,
      selectStudentId: null,
      ruleFormReset: null, //重置时的表单数据
      Levels: [], //处分等级集合
      Reasons: [], //处分原因集合
      ClassList: [], //班级集合
      StudentList: [], //班级学生集合
      Semesters: [], //学期集合
      Title: "新增处分信息",
      fullscreenLoading: false,
      uploadFiles: [],
      rules: {
        remark: [{
          max: 500,
          message: '备注不能超过500个字符',
          trigger: 'change'
        },],
        reason: [{
          required: true,
          message: '请输入处分原因',
          trigger: 'change'
        },],
        semesterId: [{
          required: true,
          message: '请选择学期',
          trigger: 'change'
        },],
        punishDate: [{
          required: true,
          message: '请选择处分时间',
          trigger: 'change'
        },],
        levelId: [{
          required: true,
          message: '请选择处分级别',
          trigger: 'change'
        },],
      },
    };
  },
  created() {
    let that = this;
    let id = that.$route.params.id;
    var url = that.$route.path.toLowerCase();

    var detailUrl = null;
    if (id != null && typeof (id) != "undefined" && id != "") {
      that.Title = "编辑处分信息";
      that.ruleForm.reasonId = id;
      detailUrl = "/api/admin/punish/" + id;
    }
    that.fullscreenLoading = true;

    // 处分原因
    ajaxGet(that, "/api/admin/PunishReason", null, function (r1) {
      that.Reasons = r1;
    })

    // 处分级别
    ajaxGet(that, "/api/admin/PunishLevel", null, function (r1) {
      that.Levels = r1;
    })
    that.allStudents = [];
    let apiUrl = "/api/admin/student/bystatus?status=1";
    if (
      url == "/admin/adviser/studentprize/create" ||
      url.indexOf("/admin/adviser/studentprize/edit") != -1
    ) {
      // 班主任
      apiUrl += "&isAdviser=true";
    } else if (
      url == "/admin/studentprize/create" ||
      url.indexOf("/admin/studentprize/edit") != -1
    ) {
      // 管理员
      apiUrl += "&isAdviser=false";
    }
    ajaxGet(that, apiUrl, null, function (resStudent) {
      that.optAllStudent = resStudent;
      that.$refs.LinkageClass.onGetData(resStudent);
      if (!regNull(that.$route.params.id)) {
        ajaxGet(that, detailUrl, null, function (resData) {
          if (resData != null) {
            that.ruleForm = resData;
            that.uploadFiles = [];
            if (resData.attachment != null && typeof (resData.attachment) != "undefined" && resData.attachment != "") {
              var files = resData.attachment.split('|');
              for (var i in files) {
                var it = i + 1;
                that.uploadFiles.push({
                  uid: it,
                  path: files[i],
                });
              }
            }
            if (!regNullArray(resData.members)) {
              let tempArr = resData.members.map((item) => item.studentId);
              that.$refs.LinkageClass.onMultipleData(tempArr);
              // that.onChangeLinkageClass(tempArr)
              that.selectStudentList = []; // 要重置一下数据，以免与控件无法联动
              resData.members.forEach((item) => {
                let temp = that.optAllStudent.find((fd) => fd.studentId == item.studentId);
                if (!regNull(temp)) {
                  that.selectStudentList.push({
                    studentName: temp.name,
                    className: temp.class,
                    classId:temp.classId,
                    studentNo: temp.studentNo,
                    studentId: temp.studentId,
                    levelId: item.levelId,
                  });
                }
              });
            }
            that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
            that.fullscreenLoading = false;
            var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
            that.random += random;
          } else {
            that.fullscreenLoading = false;
            that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
          }
        });
      } else {
        that.fullscreenLoading = false;
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));

      }
    })


  },

  methods: {
    /**
* @description 联动选择触发事件
* @param {Object} objItem
*/
    onChangeLinkageClass(objItem) {
      let that = this;
      that.historyStudent = that.selectStudentList;
      that.selectStudentList = []; // 要重置一下数据，以免与控件无法联动
      if (!regNullArray(objItem)) {
        objItem.forEach((item) => {
          let temp = that.optAllStudent.find((fd) => fd.studentId == item);
          if (!regNull(temp)) {
            that.selectStudentList.push({
              studentName: temp.name,
              className: temp.class,
              studentNo: temp.studentNo,
              studentId: temp.studentId,
              classId:temp.classId,
            });
          }
        });
        if (!regNullArray(that.historyStudent)) {
          for (var it of that.selectStudentList) {
            let temp2 = that.historyStudent.find(fd2 => fd2.studentId == it.studentId);
            if(!regNull(temp2)){
              if (!regNull(temp2.levelId)) {
                it.levelId = temp2.levelId
              }
            }
            
          }
        }
      }
    },
    /**
     * @description 新增处分原因
     */
    onGoCreate() {
      this.$router.push('/Admin/DataDictionary/Punish/Reason/Create');
    },

    /**
     * @description 整理附件
     */
     onTidyAttachment(objItem) {
      let that = this;
      that.uploadFiles = [];
      // 整理附件
      if (!regNull(objItem)) {
        var files = objItem.split("|");
        for (var i in files) {
          var it = i + 1;
          that.uploadFiles.push({
            uid: it,
            path: files[i],
          });
        }
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
      }
    },
    /**
     * @description 附件上传成功后事件
     * @param {Object} param
     */
    onPictureHandleSuccess(param) {
      let that = this;
      that.ruleForm.attachment = "";
      for (var i in param) {
        if (that.ruleForm.attachment == "") {
          that.ruleForm.attachment = param[i].path;
        } else {
          that.ruleForm.attachment += "|" + param[i].path;
        }
      }
    },
    /**
     * @description 重置事件
     */
    onReset() {
      let that = this;
      that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
      let tempArr = that.ruleForm.members.map((item) => item.studentId);
      that.$refs.LinkageClass.onMultipleData(tempArr);
      that.onChangeLinkageClass(tempArr);
      that.onTidyAttachment(that.ruleForm.attachment);
      var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
      that.random += random;
      try {
        that.$refs["ruleForm"].resetFields();
      } catch { }
    },


    /**
     * @description 提交事件
     */
    onSave() {
      let that = this;

      that.$refs["ruleForm"].validate(valid => {
        if (valid) {
          var configs = [];
          // for (var i in that.ruleForm.GrowthConfigs) {
          //   var config = that.ruleForm.GrowthConfigs[i];
          //   if (config.isSelect == true) {
          //     var newConfig = {
          //       configId: config.configId,
          //       score: JSON.parse(config.score)
          //     };
          //     configs.push(newConfig);
          //   }
          // }
          if (regNullArray(that.selectStudentList)) {
            warning(that, "请选择学生");
            return false;
          }
          let tempMember = [];
          if (that.ruleForm.punishId == null) {
            for (let item of that.selectStudentList) {
              tempMember.push({
                studentId: item.studentId,
                classId: item.classId,
                levelId: item.levelId,
                // configs: configs
              })
            }
          } else {
            for (let item of that.selectStudentList) {
              tempMember.push({
                studentId: item.studentId,
                classId: item.classId,
                levelId: item.levelId,
                // configs: configs
              })
            }
          }

          // 设置提交参数
          let _data = {
            reason: that.ruleForm.reason,
            punishNo: that.ruleForm.punishNo,
            reasonId: that.ruleForm.reasonId,
            levelId: that.ruleForm.levelId,
            punishDate: that.ruleForm.punishDate,
            attachment: that.ruleForm.attachment,
            remark: that.ruleForm.remark,
            members: tempMember
          }
          if (that.ruleForm.punishId == null) {
            that.fullscreenLoading = true;
            ajaxPost(that, '/api/admin/punish', _data, function (r) {
              that.fullscreenLoading = false;
              that.onBack();
            });
          } else {
            that.fullscreenLoading = true;
            ajaxPut(that, "/api/admin/punish/" + that.ruleForm.punishId, _data, function (r) {
              that.fullscreenLoading = false;
              that.onBack();
            });
          }
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].querySelector('input').focus();
          }, 1)
          return false;
        }
      });
    },


    /**
     * @description 删除学生事件
     * @param {Object} studentId
     */
    onDeleteSelect(studentId) {
      let that = this;
      var index = -1;
      for (var i in that.ruleForm.members) {
        var member = that.ruleForm.members[i];
        if (member.studentId == studentId) {
          index = i;
          break;
        }
      }
      if (index >= 0) {
        that.ruleForm.members.splice(index, 1);
        that.onRefreshStudent();
      }

      index = -1;
      for (var i in that.selectStudentList) {
        var stu = that.selectStudentList[i];
        if (stu.studentId == studentId) {
          index = i;
          break;
        }
      }
      if (i >= 0) {
        that.selectStudentList.splice(index, 1);
      }

    },
    onBack() {
      let that = this;
      back(that);
    },
  },
};
</script>

<style scoped="scoped">
label {
  margin-bottom: 0;
}

.inputGroupCont {
  padding-top: 15px;
}

.inputGroupCont .inputList {
  padding-bottom: 10px;
}

.spIcon {
  width: 5px;
  height: 20px;
  border-radius: 5px;
  background-image: linear-gradient(#4545FF, #2D8EFF);
}

.spTitle {
  float: left;
  margin-left: 20px;
  color: #323E56;
  letter-spacing: 2px;
  font-size: 16px;
  margin-top: -3px;
}

.wMax {
  width: 100% !important;
}
</style>
