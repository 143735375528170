<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
      <el-card class="card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            日志详情
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="姓名" class="colInfo">
                {{ruleForm.name | flNoValue}}
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="操作时间" class="colInfo">
                {{dateFormat('YYYY-mm-dd HH:MM:SS',ruleForm.createDate) | flNoValue}}
                <!-- {{ruleForm.createDate | flNoValue}} -->
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="链接地址" class="colInfo">
                {{ruleForm.url | flNoValue}}
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="操作内容" class="colInfo">
                {{ruleForm.message | flNoValue}}
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        fullscreenLoading: false,
        ruleForm: {
          logId: '', //ID
          logType: '', //异常类型
          message: '', //异常消息
          url: '', //异常地址
          stackTrace: '', //堆栈消息
          createDate: '', //异常时间
        },
      };
    },
    methods: {
      // 返回
      onBack() {
        let _this = this;
        back(_this);
      },
      //时间格式转换
      dateFormat(fmt, date) {
        let ret = "";
        date = new Date(date);
        const opt = {
          'Y+': date.getFullYear().toString(), // 年
          'm+': (date.getMonth() + 1).toString(), // 月
          'd+': date.getDate().toString(), // 日
          'H+': date.getHours().toString(), // 时
          'M+': date.getMinutes().toString(), // 分
          'S+': date.getSeconds().toString() // 秒
          // 有其他格式化字符需求可以继续添加，必须转化成字符串
        }
        for (let k in opt) {
          ret = new RegExp('(' + k + ')').exec(fmt)
          if (ret) {
            fmt = fmt.replace(
              ret[1],
              ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
            )
          }
        }
        return fmt
      }
    },
    filters: {
      flNoValue(obj) {
        if (obj == null || typeof(obj) == "undefined" || obj == '') {
          return '暂无数据';
        }
        return obj;
      }
    },
    created() {
      let _this = this;
      // 日志ID
      let logID = _this.$route.params.Id;
      //判断是否有传值过来
      if (logID != null && typeof(logID) != "undefined" && logID != "") {
        _this.ruleForm.logId = logID;
        var apiUrl = '/api/admin/oplog/' + logID;
        _this.fullscreenLoading = true;
        ajaxGet(_this, apiUrl, null, function(obj) {
          _this.ruleForm = obj;

          _this.fullscreenLoading = false;
        });
      } else {
        _this.onBack();
      }
    }
  }
</script>

<style>
</style>
